.button-style {

    cursor: pointer;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid  lightgrey-color;
    border-radius:1px;
    background-color: lightgrey-color;
    position:relative;
    display:inline-block;
    
    &.text-style {
        color:white;
    }
    &.-active {
        outline:none;
        border: 1px solid  #585858;
        background-color: #585858;
    }

    &.-action {
        background-color: brand-color;
        color: #fff;
    }

    &.-link {
        background: unset;
        border: none;
        padding: 0;

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.33;
        }

        &[hidden] {
            display: none;
        }

        &:hover {
            &:not( [disabled] ) {
                color: action-color;
            }
        }
    }
}
