.hotspot-point {
	height: rem(44px);
	width: rem(44px);
	border-radius: rem(22px);
	background-color: rgba(255, 255, 255, 0.9);
	border: rem(10px) solid rgba(0, 0, 0, 0.1);
	box-shadow: rem(0px) rem(2px) rem(6px) rgba(0, 0, 0, 0.2);
	transform: translate(-50%, -50%);
}

.hotspot-rect {
	background-color: rgba( 0, 0, 0, 0.2 );
	border: 1px solid theme.colors.text-inverted;
}
