.chapter-item {
    
    min-width: 154px;
    height: 96px;
    border: 2px solid darkgrey-color;

    display: flex;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    position: relative;

    &.-current {
        border: 2px solid action-color;
    }

    > .title {
        opacity: 0;
        transition: opacity 1s;
        padding: 5px;
        background-color: rgba(lightgrey-color, 0.9);
        color: #fff;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        > .time {
            display: inline-block;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: border-radius;
            color: #fff;
            padding: rem(3px);
            font-size: rem( 10px );
            line-height: rem( 12px );
            min-width: rem(12px);
            text-align: right;
            transform: translateY((0 - rem(3px)));
        }
    }

    &:hover > .title {
        opacity: 1;
    }
}