.cloze-container {
    margin: rem(15px) 0;

    > input[type='text'].gap {
        box-sizing: content-box;
        line-height: rem(28px);
        background-color: #f7f7f7;
        outline: none;
        border-radius: rem(2px);
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid rem(1px) #ccc;
        transition: background-color 0.33s, border-bottom 0.33s;

        &:active, &:focus {
            background-color: #eee;
            border-bottom: solid rem(1px) #444;
        }
    }
}