.quiz-container {
    position: absolute;
    top: 50px;
    left: 50px;
    min-width: rem(600px);
    > .quiz-element {
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.75);
        box-shadow: rem(2px) rem(2px) rem(8px) rgba(0, 0, 0, 0.5);
    }
}