.previous-next-navigation {
    gap: 16px;

    > * {
        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}
