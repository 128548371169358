/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2014 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      exljbris Font Foundry
 * License URL: http://www.fontspring.com/fflicense/exljbris
 *
 *
 */

@css {
  @font-face {
      font-family: 'Museo Sans';
      font-weight: normal;
      src: url('../fonts/MuseoSans_100-webfont.eot');
      src: url('../fonts/MuseoSans_100-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_100-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_100-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_100-webfont.svg#webfont') format('svg');
  }

  @font-face {
      font-family: 'Museo Sans';
      font-weight: normal;
      font-style: italic;
      src: url('../fonts/MuseoSans_100_Italic-webfont.eot');
      src: url('../fonts/MuseoSans_100_Italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_100_Italic-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_100_Italic-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_100_Italic-webfont.svg#webfont') format('svg');
  }

  @font-face {
      font-family: 'Museo Sans';
      font-weight: 500;
      src: url('../fonts/MuseoSans_300-webfont.eot');
      src: url('../fonts/MuseoSans_300-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_300-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_300-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_300-webfont.svg#webfont') format('svg');
  }

  @font-face {
      font-family: 'Museo Sans';
      font-weight: 500;
      font-style: italic;
      src: url('../fonts/MuseoSans_300_Italic-webfont.eot');
      src: url('../fonts/MuseoSans_300_Italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_300_Italic-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_300_Italic-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_300_Italic-webfont.svg#webfont') format('svg');
  }

  @font-face {
      font-family: 'Museo Sans';
      font-weight: bold;
      src: url('../fonts/MuseoSans_700-webfont.eot');
      src: url('../fonts/MuseoSans_700-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_700-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_700-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_700-webfont.svg#webfont') format('svg');
  }

  @font-face {
      font-family: 'Museo Sans';
      font-weight: bold;
      font-style: italic;
      src: url('../fonts/MuseoSans_700_Italic-webfont.eot');
      src: url('../fonts/MuseoSans_700_Italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('../fonts/MuseoSans_700_Italic-webfont.woff') format('woff'),
           url('../fonts/MuseoSans_700_Italic-webfont.ttf') format('truetype'),
           url('../fonts/MuseoSans_700_Italic-webfont.svg#webfont') format('svg');
  }
}
