.lernvideo-element {

    >.quiz-element {

        //overwrite default element-layout
        &.element-layout {
        margin-bottom: 0;

            &.-read {
               max-width: none;
            }
        }
        &.-video {
            background: rgba(255,255,255,0.7)
            padding: rem(16px);
        }
        &.rollover {
            left: rem(16px);
            right: rem(16px);
        }

        .button-style {

            &.-action {
                position: absolute;
                right: 20px;
                bottom: 70px;
            }
        }
    }
}