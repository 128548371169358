.menu-module {
    cursor:pointer;
    border-left: 5px solid #fff;
    border-bottom: 1px solid grey-color;
    >.item {
        overflow: hidden;
    }
    
    &:hover {
        border-left: 5px solid brand-color;
        background-color: grey-color;
    }
    >.exam-state {
        text-align:right;
    }

}