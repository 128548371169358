
.feedback-panel {
	font-family: Georgia, serif;
	font-weight: 400;
	font-size: 20px;
	line-height: 31px;

	padding: rem(40px) rem(30px) rem(20px) rem(30px);
	
	margin: rem(-24px) 0 0 0;

	&.-correct {
		border: 2px solid xdarkgreen-color;
		background-color: lightgreen-color;
		color: green-color;
	}
	
	&.-wrong {
		border: 1px solid darkred-color;
		background-color: lightred-color;
		color: red-color;
		
	}

}