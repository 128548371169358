.chapter-control{

    height: 100px;
    overflow: hidden;
    background-color: #000;

    .items{
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: content-box;
        padding-bottom: 20px;
    }
    
}