@import "@lernetz/css-lib/stylus/themes/lernbuch/setup";
@require "./fonts/Museo_Sans.styl";
//@import "./components/**/*.var.styl";

grey-color = #F4F4F4;
darkgrey-color = #D7D7D7;
lightgrey-color = #757575;

text-color = #202023;
action-color = #e60000;

brand-color = #e60000;
download-color = #757575;

lightgreen-color = #EFF2DD;
green-color = #525009;
darkgreen-color = #EFF2DD;
xdarkgreen-color = #C2C89F;

lightred-color = #F8E6E7;
red-color = #e60000;
darkred-color = #DFC0C6;

border-radius = 2px;

theme.settings.heading-font = 'Museo Sans'\, sans-serif;
theme.settings.text-font = 'Museo Sans'\, sans-serif;


// add your variable adjustments here:
theme.components.grid-layout.sizes = {
	'1':100%,
	'1-2':50%,
	'1-3':100%/3,
	'2-3':100%/3*2,
	'1-4':100%/4,
	'3-4':100%/4*3,
	'1-5':100%/5,
	'2-5':100%/5*2,
	'3-5':100%/5*3,
	'1-6':100%/6,
}


theme.components.grid-layout.breakpoints = {
		small: rem( 768px ),
		medium: rem( 992px ),
		large: rem( 1200px )
	}

theme.components.color-box.colors.grey=grey-color;
theme.components.color-box.colors.darkgrey=darkgrey-color;

theme.components.color-box.colors.brand=#840607;

theme.components.margin-box = {
  default: rem( 20px ),
  small: rem( 15px ),
  xsmall: rem( 10px ),
  medium: rem( 30px ),
  //large: rem( 60px ),
  large: rem(80px),
  xlarge: rem(120px),
  auto: auto,
}

theme.components.shadow-box.darker = {
	box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.5),
}

theme.components.text-style.step-title = {
	font-weight: bold,
	font-size: rem( 19px ),
	color: theme.colors.action,
}

theme.components.text-style.blue = {
	color: theme.colors.action,
}


@import "@lernetz/css-lib/stylus/themes/lernbuch/components";
@import "./components/**/*.styl";

body {
	font-family: 'Museo Sans', sans-serif;
}
