.language-switcher {
    position: relative;
    text-transform: uppercase;

    .current {
        cursor: pointer;
        .item {
            font-weight: bold;
            color: inherit;
        }
        .svg-icon {
            width: 16px;
            height: 16px;
        }
    }

    .all {
        display: none;
        @media ( min-width: 600px ) {
            display: block;
        }

        .item + .item::before {
            display: inline;
            content: ' | ';
            white-space: nowrap;
        }
    }

    &.-expanded {
        .all {
            display: block;
        }
        .current .svg-icon {
            transform: scaleY( -1 );
        }
    }

    @media( max-width: 600px ) {
        .all {
            position: absolute;
            top: 24px;
            right: 0;
            background: #fff;
            border: solid 1px #ccc;
            padding: 6px 14px;
            box-shadow: 0px 1px 6px 0px rgba( 0, 0, 0, 0.17 );

            .item {
                display: block;
                text-align: center;
                & + .item::before {
                    display: none;
                }
            }
        }
    }

    @media( min-width: 600px ) {
        .current {
            display: none;
        }
        .all {
            display: block;
        }
    }

    >.item {
            color: black;
        &.-active {
            color:brand-color;
        }
    }
}