.foldable-hidden-element {

    >.toggler {
        cursor: pointer;
        >.title {
            display:inline-block;
        }
        >.foldable-icon {
            >.svg-icon {
                transition: transform ease .2s;
                width: 16px;
                height: 16px;
                color: brand-color;
            }
        }
    }

    >.body {
            max-height: 0px;
            overflow:hidden;
            transition: max-height 0.2s ease-out;
              >ul, >ol {
                margin-left: 22px;
            }

    }

    &.-expand {
        .svg-icon {
            transform: rotate(180deg);
            transition: transform ease .2s;
        }
        >.body {
            border-left: 5px solid brand-color;
        }
    }


}