.element-layout {

    &.-read,
    &.-extended {
        padding-left: 12px;
        padding-right: 12px;
    }

    &.title-element {
        &.text-style {
            &.-h1 {
                margin-bottom: rem(30px);
            }
            &.-h2 {
                margin-bottom: rem(20px);
            }
            &.-h3 {
                margin-bottom: rem(10px);
            }
            &.-h4 {
                margin-bottom: rem(10px);
            }
        }

    }

    &.paragraph-element {
        &.text-style { 
            &.-p {
                margin-bottom: rem(40px);
            }
        }
    }


    
    &.foldable-hidden-element, &.image-element, &.lernvideo-element, 
    &.video-element, &.composite-material, &.tooltip-element {
        margin-bottom: rem(40px);
    }
    &.quiz-element {
        margin-bottom: rem(80px);
    }


    //special cases
    &.paragraph-element + .title-element&.-h2{
        margin-top: rem(80px);
    }
    &.lernvideo-element + .foldable-hidden-element {
        margin-top: rem(-20px);
    }
    &.video-element + .foldable-hidden-element {
        margin-top: rem(-20px);
    }

}

@media all and ( max-width: theme.components.grid-layout.breakpoints.small ) {
    .element-layout {

        .quiz-element {
            margin-bottom: rem(60px);
        }
    }
}

