.progress-chart {
    
    >.container {
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background-color: darkgrey-color;
        >.progress {
            height: 100%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}