// NOTE: If another drag & drop element with own `.drag-node` etc. styles
// is brought back, we'd need to re-enable this commented-out parent style.
// For now, leaving it commented-out means that the drag nodes will
// display properly during a drag. With the parent style enabled, it will
// be sized incorrectly (among other things).

// .drag-drop {

  .drag-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding-top: rem(7.5px);
    padding-bottom: @padding-top;
    min-height: rem(110px);
  }

  .drag-node {
    border: solid rem(1px) #979797;
    background: #e8e8e8;
    cursor: grab;
    width: rem(175px);
    margin: rem(7.5px);

    > .text {
      padding: rem(5px) rem(7.5px);
    }

    > .image {
      width: 100%;
    }
  }

  .drop-node {
    width: 100%;
    padding-top: rem(7.5px);
    padding-bottom: @padding-top;

    > .text {
      font-weight: bold;
    }
  }

  .drop-target {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    background-color: #f4f4f4;
    min-height: rem(110px);
    width: 100%;

    >.drag-node {
        display:inline-block;
    }
  }
// }