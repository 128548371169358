.confirmationmail-container {

    .error-message, .success-message {
        display: block;
        color: brand-color;
        &.-hidden {
            display: none;
        }
    }

    .form {
        .email {
            padding: 10px 0px 10px 10px;
            width: 50%;
        }

        &.-hidden {
            display:none;
        }
    }


    &.-invalid-syntax {
        .error-message.-invalid-syntax {
            display:block;
        }
        .email:not(.-repeat) {
            border: solid 1px brand-color;
        }
    }

    &.-emails-do-not-match {
        .error-message.-emails-do-not-match {
            display:block;
        }
        .email.-repeat {
            border: solid 1px brand-color;
        }
    }
}