.flip-cards-element {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat( 1, minmax( 0, 1fr ) );

    @media( min-width: 768px ) {
        grid-template-columns: repeat( 2, minmax( 0, 1fr ) );
    }

    @media( min-width: 992px ) {
        grid-template-columns: repeat( 3, minmax( 0, 1fr ) );
    }
}

.flip-cards-element > .flip-card {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    padding-top: (0.5 * 100%);
    min-height: 365px;
    @media( min-width: 768px ) {
        min-height: 400px;
        padding-top: (1.10 * 100%);
    }
    @media( min-width: 992px ) {
        padding-top: (1.64 * 100%);
    }

    cursor: pointer;
    user-select: none;

    perspective: 1000px;

    > .faces {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        transition: all 0.4s;
        transform-style: preserve-3d;

        > .face {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;

            backface-visibility: hidden;
            transition: inherit;

            display: flex;
            align-items: center;
            justify-content: center;

            box-shadow: 0px 1px 6px 0px rgba( 0, 0, 0, 0.17 );

            color: text-color;

            &.-front {
                transform: rotateY(0deg);  // required for `backface-visibility` to work
            }

            &.-back {
                transform: rotateY(180deg);
            }

            > .image {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            > .background {
                transition: inherit;
                background-color: white;
            }

            > .toggle {
                transition: inherit;
            }

            &.-has-image {
                color: white;
                text-shadow: 0 2px 4px rgba( 0, 0, 0, 0.2 );

                > .background {
                    background-color: rgba( text-color, 0.4 );
                }
            }

            .text-style {
                color: inherit;
            }
        }
    }

    &.-flipped > .faces {
        transform: rotateY(180deg);
    }

    &:hover > .faces,
    &:focus-within > .faces {

        > .face {
            box-shadow: 0px 1px 6px 0px rgba( 0, 0, 0, 0.34 );
        }
    }
}
