.underline-link {
    position: relative;
    color: theme.colors.text;
    text-decoration: none;
    cursor: pointer;


    > .link:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: theme.colors.text;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    > .link:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

}

.underline-link:hover {
    color: theme.colors.text;
}