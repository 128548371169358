.menu-hamburger {

        cursor:pointer;
        .svg-icon > .close {
            display:none;
        }

    &.-open {
        .svg-icon > .open {
            display:none;
        }
        .svg-icon > .close {
            display:block;
        }
    }
}