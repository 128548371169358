.tooltip-element.-button {
    position: relative;

    .button {
        width: rem( 40px );
        height: @width;
        border-radius: 100%;
        background: rgba( 255, 255, 255, 0.8 );
        box-shadow: 0 0 5px rgba( 0, 0, 0, 0.8 );
        color: #444;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        user-select: none;
        margin: 0;
        z-index: 0;
        transform: translate( -50%, -50% );

        transition: all 0.1s;
        &:hover {
            width: rem( 42px );
            height: @width;
        }
    }

    .tooltip {
        margin-bottom: 8px;
        margin-top: 8px;
        position: absolute;
        z-index: 1;

        &.-hidden {
            display: none;
        }
    }
}
