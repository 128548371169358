.lernvideo{

	width: 700px;
	position: relative;

	video{
		width: 100%;
	}
	
	&:-webkit-full-screen{
		width: 100vw;
		margin: auto 0;
	}

}