.composite-material {

    .link, .download {
        .icon {
            color: brand-color;
            position:relative;
            //top: 6px;
            margin-right:10px;
        }
    }


}