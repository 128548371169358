.title-element {
    //display:inline;
    .svg-icon {
        
        color: brand-color;
        height: rem(30px);
        width: rem(30px);  
    }
    .title {
        vertical-align:text-bottom;
    }
}

@media all and ( max-width: theme.components.grid-layout.breakpoints.small ) {
    .svg-icon {
        height: rem(24px);
        width: rem(24px);  
    }
}