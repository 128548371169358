.foldable-preview-element {

    >.toggler {
        cursor: pointer;
        text-align: right;
        position:relative;
        top: -30px;
        right: 30px;
        >.title {
            display:inline-block;
        }
        >.foldable-icon {
            vertical-align:middle;
            >.svg-icon {
                transition: transform ease .2s;
                width: 16px;
                height: 16px;
                color: brand-color;
            }
        }
    }

    >.body {
            //set this value corresponding to foldable.ts this.previewHeight
            height: rem(150px);
            background-color: grey-color;
            	
            //max-height: none;
        >.inner {
            height: rem(100px);
            overflow: hidden;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                height: 2.1875rem;
                background: linear-gradient(to bottom, rgba(255,255,255,0), grey-color 70%);
            }
        }

    }


    &.-expand {
        .svg-icon {
            transform: rotate(180deg);
            transition: transform ease .2s;
        }
         >.body {

            height: auto;
            
            >.inner {
                overflow:initial;
                height:auto;
                margin-bottom: rem(40px);
                transition: max-height 0.2s ease-out;
                &:after {
                    content: none;
                }
            }
        }
    }
    

}