$heading-font = theme.settings.heading-font;
$text-font = theme.settings.text-font;

.text-style {
    color: text-color;
    -webkit-font-smoothing: antialiased;
    &.-h1 {
        font-family: $heading-font;
        font-weight: 500;
        font-size: rem(48px);
        line-height: rem(58px);
    }

    &.-h2 {
        font-family: $heading-font;
        font-weight: 500;
        font-size: rem(30px);
        line-height: rem(42px);
    }
    &.-h3 {
        font-family: $text-font;
        font-weight: bold;
        font-size: rem(26px);
        line-height: rem(36px);
    }

    &.-h4 {
        font-family: $text-font;
        font-weight: bold;
        font-size: rem(21px);
        line-height: rem(31px);
    }
    &.-h5, &.-h6 {
        font-family: $text-font;
        font-weight: 400;
        font-size: rem(17px);
        line-height: rem(24px);
    }

    &.-p {
        font-family: $text-font;
        font-weight: 400;
        font-size: rem(20px);
        line-height: rem(34px);
    }

    &.-p-link {
        font-family: $text-font;
        font-weight: 400;
        font-size: rem(21px);
        line-height: rem(34px);
        color: text-color;
        text-decoration: underline;
        transition: color 0.2s;
        &:hover, &:focus {
            color: brand-color;
            text-decoration: none;
        }
    }
    

    &.-p-small {
        font-family: $text-font;
        font-weight: 400;
        font-size: 17px;
        line-height: 27px;
    }

    &.-p-intro{
        font-family: $text-font;
        color: text-color;
        font-weight: 400;
        font-size: 26px;
        line-height: 34px;
    }

    &.-p-app {
        font-family: $heading-font;
        font-weight: 700;
        font-size: 19px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-align: center;
    }
    &.-p-navi {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.2px;
        &:hover {
            color:brand-color;
        }
    }
    &.-p-caption {
        font-family: $heading-font;
        font-weight: 400;
        color: #585858;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 21px;
    }
    //links inside -p
    &.-p a {
        color: text-color;
        text-decoration: underline;
        transition: color 0.2s;
        &:hover, &:focus {
            color:brand-color;
            text-decoration: none;
        }
    }

    &.-center {
        text-align: center;
    }

    &.-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media all and ( max-width: theme.components.grid-layout.breakpoints.small ) {
    .text-style {
        &.-h1 {
            font-size: 45px;
            line-height: 55px;
        }

        // &.-h2 {
        //     font-size: 30px;
        //     line-height: 40px;
        // }

        &.-h3 {
            font-size: 24px;
            line-height: 34px;
        }

        &.-h4 {
            font-size: 20px;
            line-height: 28px;
        }

        &.-p {
            font-size: 17px;
            line-height: 27px;
        }

        &.-p-small {
            font-size: 17px;
            line-height: 27px;
        }

        &.-p-intro{
            font-size: 24px;
            line-height: 34px;
        }

    }
}
