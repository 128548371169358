.fixed-page-layout.-lernmodule {
	
	>.header {
		padding: 16px 12px;
		@media ( min-width: 600px ) {
			padding: 16px 32px;
		}

		.home-button {
			cursor:pointer;
			&.-disabled {
				visibility: collapse;
			}
		}
	}

	> .content {
		margin-top: 56px;
        height: 100vh;
	}
}