.page-footer {

    .logos {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
        @media ( min-width: 600px ) {
            flex-flow: row wrap;
        }

        > .logo-anchor + .logo-anchor {
            margin: 24px 0 0 0;
            @media ( min-width: 600px ) {
                margin: 0 0 0 24px;
            }
        }
    }

    .logo {
        width: auto;
        height: 80px;
        display:inline-block;
    }

    > .element-layout {
        display: flex;
        justify-content: space-between;
    }

    @media all and ( max-width: theme.components.grid-layout.breakpoints.small ) {
        > .element-layout {
            display: block;
        }
    }

}