.composite-article {
    
    &:not(:last-child) {
        margin-top: rem(40px);
        margin-bottom: rem(40px);
    }

    &.element-layout {
        &.-full{
            background-color: grey-color;
            padding: rem(50px) 0;

            //hack: ignore element layout, use extended
            .element-layout {
                        //max-width:none;
                        max-width: 58.75rem;
            }
            .element-layout {
                &.paragraph-element {
                    margin-bottom:0;
                    .text.style {
                        &.-p {
                            margin-bottom:none;
                        }
                    }
                    ul, ol {
                        margin-left: 0;
                    }
                }
            }
            

        }
    }
    .line {
        color: brand-color;
        width: 20px;
        height: 5px;
    }

    ul {
        list-style-type: none;
          > li:before {
            content: '–';
            font-weight: bold;
            color: brand-color;
        }
         >li {
            padding-left: rem(16px);
            text-indent: rem(-16px);
        }

    }





    //copy from https://stackoverflow.com/questions/5732836/html-css-numbered-list-with-numbers-inside-of-circles
    ol {
        counter-reset:li; /* Initiate a counter */
        margin-left:0; /* Remove the default left margin */
        padding-left:0; /* Remove the default left padding */
    }
    ol > li {
        position:relative; /* Create a positioning context */
        margin:0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
        
        padding: 6px 16px;
        list-style:none; /* Disable the normal item numbering */
        //border-top:2px solid #666;
        background:#f6f6f6;
    }
    ol > li:before {
        content:counter(li); /* Use the counter as content */
        counter-increment:li; /* Increment the counter by 1 */
        /* Position and style the number */
        position:absolute;
        top:6px;
        left:-2em;
        -moz-box-sizing:border-box;
        -webkit-box-sizing:border-box;
        box-sizing:border-box;
        width:2em;


        width: 34px;
        height: 34px;
        /* Some space between the number and the content in browsers that support
        generated content but not positioning it (Camino 2 is one example) */
        //margin-right:32px;
        padding:1px;
        //border-top:2px solid #666;
        border-radius: 50%;
        background: brand-color;
        color:#fff;
        //background:#666;
        font-weight:bold;
        font-family:"Helvetica Neue", Arial, sans-serif;
        text-align:center;
    }
    li ol,
    li ul {margin-top:6px;}
    ol ol li:last-child {margin-bottom:0;}
}