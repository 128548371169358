.module-box {

    cursor:pointer;

    &:hover {
        transform: scale(1.03, 1.03);
    }
    &.-failed {
        > .shadow-box {
            background-color: rgba(132,6,7,0.1) 
            .overflow-text:after {
                background: linear-gradient(to bottom,rgba(132,6,7,0),#F2E6E6 70% );
            }
        }

        &:hover {
            transform: none;
            cursor:default;
        }
    }

    &.-success {
        >.shadow-box {
            background-color: rgba(82,80,9,0.1) 
            .overflow-text:after {
                background: linear-gradient(to bottom,rgba(82,80,9,0),#EDEDE7 70% );
            }
        }


        &:hover {
            transform: none;
            cursor:default;
        }

    }


    &.-disabled {
            > .shadow-box {
                background-color: rgba(169,169,169,0.1) 
                .overflow-text:after {
                    background: linear-gradient(to bottom,rgba(169,169,169,0),#F6F6F6 70% );
                }
            }

            &:hover {
                transform: none;
                cursor:default;
            }
        }


    .content {
        >.title {
            height:58px;
            overflow: hidden;
        }
        >.description {
            height: 88px;
        }
        .duration {
            text-align: right;
        }
    }

    .progress-chart >.container {
        width: 60px;
    }

}

@media all and ( max-width: theme.components.grid-layout.breakpoints.small ) {
    // .module-box {
    //     .image {
    //         max-height: 200px;
    //     }
    // }
}