.answer-view {
    
    input[type="radio"] {
        //margin-left: -23px;
        margin-top: 7px;
        position: absolute;
    }
    input[type="checkbox"] {
        //margin-left: -23px;
        margin-top: 9px;
        position: absolute;
    }

    .text {
        margin-left: rem(25px);
        //display: inline;
        
    }

    &.-correct {
        background-color: #f1f9ee;
        color: #47882d;
    }
    &.-wrong {
        color: #9B2727;
        background-color: #fdf7f7;
    }
}